import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/';
import { 
    FrequencyField
} from '../../components/library/entitysync'; 


function inputSelect() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <FrequencyField ampm="am" />
                <FrequencyField ampm="pm" />
                <hr />
                <FrequencyField ampm="pm" label="Remeber this other thing" name="thing" />
            </Entity>
        </LibraryWrapper>
    )
}
export default inputSelect
